/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
import { GoMail } from 'react-icons/go';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import Text from '@/components/atoms/Text';
import { SvgRectangles } from '@/components/atoms';
import Image from '@/components/atoms/Image';
import styles from './newsletter.module.scss';
import useDeviceType from '@/hooks/useDeviceType';
import variables from '@/styles/variables';
import newsletterAPI from '@/api/newsletter';
import createUUID from '@/utils/createUUID';
import isValidEmail from '@/services/formValidators/isValidEmail';
import MA from '@/services/marketingAutomation';

const toastify = () =>
  import(/* webpackChunkName: "toastify" */ '@/services/toastify');

const haveImageInPosition = (position, newsletter) => {
  const image1 =
    newsletter.position_picture_1 === position && newsletter.show_picture_1;
  const image2 =
    newsletter.position_picture_2 === position && newsletter.show_picture_2;
  if (image1) return newsletter.picture_1_image?.url;
  if (image2) return newsletter.picture_2_image?.url;
  return null;
};

const Newsletter = ({ custom }) => {
  const [emailInput, setEmailInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const storeId = useSelector((state) => state.store);

  const handleSubmit = async () => {
    const eventId = createUUID();
    const verifyEmail = isValidEmail(emailInput);
    if (verifyEmail.isValid) {
      setLoading(true);
      let response = { success: false };
      if (custom.segments) {
        response = await MA.addToNewsletter(
          emailInput.toLowerCase(),
          custom.segments
        );
      } else {
        response = await newsletterAPI.post(
          emailInput.toLowerCase(),
          storeId,
          eventId
        );
      }
      if (response.success) {
        setEmailInput('');
        toastify().then((toast) =>
          toast.success('Te suscribiste correctamente')
        );
      } else {
        toastify().then((toast) => toast.error(response.error));
      }
    } else {
      toastify().then((toast) => toast.error(verifyEmail.errorMessage));
    }
    setLoading(false);
  };

  const { isDesktop } = useDeviceType();
  const [newsletter, setNewsletter] = useState({});
  const newsletterApi = useSelector((state) => state.newsletter);

  useEffect(() => {
    if (newsletterApi) {
      setNewsletter({
        ...newsletterApi,
        leftImage: haveImageInPosition('left', newsletterApi),
        rightImage: haveImageInPosition('right', newsletterApi)
      });
    }
  }, [newsletterApi]);

  return (
    <div className={styles.container}>
      {/* -----BACKGROUND----- */}
      {newsletter?.background_image?.url ? (
        <Image
          src={newsletter.background_image.url}
          heightSkeleton="200px"
          fill
          imageStyles={{ zIndex: -1 }}
          absoluteSkeleton
          alt={newsletter.caption}
        />
      ) : (
        <div
          style={{
            background: custom?.background || '#d9d9d9',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: -1
          }}
        />
      )}
      {/* -----LEFT IMAGE----- */}
      {custom?.leftImage || newsletter?.leftImage ? (
        <div
          className={`${styles.image} ${styles.lf} ${custom && styles.custom}`}
        >
          <Image
            src={custom?.leftImage || newsletter?.leftImage}
            width={90}
            height={custom ? '100%' : 90}
            alt={newsletter?.caption}
            quality={100}
          />
        </div>
      ) : (
        isDesktop && (
          <div style={{ position: 'relative' }}>
            <div>
              <SvgRectangles
                type="newsletter"
                colored
              />
            </div>
          </div>
        )
      )}
      {/* -----NEWSLETTER FORM----- */}
      <div className={styles.newsletter}>
        <Text
          variant="h2"
          weight="bold"
          textSize="l"
          textCase="uppercase"
          textColor={
            custom?.title?.color ||
            newsletter?.text_color ||
            variables['color-black']
          }
        >
          {custom?.title?.text || 'Newsletter'}
        </Text>
        <Text
          variant="span"
          weight="semibold"
          textSize="xs"
          textColor={
            custom?.caption?.color ||
            newsletter?.text_color ||
            variables['color-black']
          }
        >
          {custom?.caption?.text ||
            newsletter?.caption ||
            'Recibí todas las novedades y descuentos especiales'}
        </Text>
        <div className={styles.form}>
          <Input
            id="email-input"
            type="email"
            placeholder="Ingresá tu mail"
            brColor="white"
            filled
            br="hard"
            variant="filled"
            textColor="black"
            value={emailInput}
            inLineStyles={{ paddingRight: '150px' }}
            onChange={(e) => {
              setEmailInput(e.target.value);
              setError('');
              setSuccess('');
            }}
            startIcon={
              <GoMail
                style={{
                  fontSize: '1.5rem',
                  position: 'absolute',
                  top: '.5rem',
                  left: '1rem'
                }}
              />
            }
          />
          <p
            style={{ color: error ? 'red' : 'green' }}
            className={styles.callResult}
          >
            {error || success}
          </p>
          <div className={styles.subscribeButton}>
            <Button
              id="button-subscribe"
              onClick={handleSubmit}
              textWeight="bold"
              label="Suscríbete"
              fullWidth
              variant="primary"
              br="hard"
              style={{
                height: '40px',
                background: newsletter.button_background_color || '',
                color: newsletter.button_text_color || '',
                border: 'none',
                boxShadow: '0px 0px 4px 0px black'
              }}
              loading={loading}
              className={styles.subscribe}
            />
          </div>
        </div>
      </div>
      {/* -----RIGHT IMAGE----- */}
      {custom?.rightImage || newsletter?.rightImage ? (
        <div
          className={`${styles.image} ${styles.lf} ${custom && styles.custom}`}
        >
          <Image
            src={custom?.rightImage || newsletter?.rightImage}
            width={90}
            height={custom ? '100%' : 90}
            alt={newsletter?.caption}
            quality={100}
          />
        </div>
      ) : (
        isDesktop && (
          <div style={{ position: 'relative', transform: 'rotate(180deg)' }}>
            <SvgRectangles
              type="newsletter"
              colored
            />
          </div>
        )
      )}
    </div>
  );
};

Newsletter.propTypes = {
  custom: PropTypes.object
};
Newsletter.defaultProps = {
  custom: {}
};

export default Newsletter;
