/* eslint-disable no-shadow */
/* eslint-disable no-sequences */
/* eslint-disable yoda */
/* eslint-disable no-return-assign */
/* eslint-disable no-bitwise */
export default function createUUID() {
  let t = new Date().getTime();
  const e = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (e) => {
    const r = (t + 16 * Math.random()) % 16 | 0;
    // eslint-disable-next-line eqeqeq
    return (t = Math.floor(t / 16)), ('x' == e ? r : (3 & r) | 8).toString(16);
  });
  return e;
}
